@use "../../../MainStyle";

.white {
  color: MainStyle.$theme-font-primary !important;
}

.icon-size {
  font-size: 20px;
}

.pro-body {
  background-color: MainStyle.$theme-background-third;
}
.register-font-color {
  color: MainStyle.$theme-font-primary;
}
span.ant-input-affix-wrapper {
  background: transparent;
}
.profile-card {
  padding-left: 1rem;
  background: MainStyle.$theme-background-secondary;
  color: MainStyle.$theme-font-primary !important;
}

.profile-card .ant-input:focus,
.profile-card .ant-select:focus .ant-select-selector,
.profile-card .ant-input:hover,
.profile-card .ant-color-picker-trigger:hover,
.profile-card .ant-select:hover .ant-select-selector {
  border-color: MainStyle.$theme-font-secondary !important;
}

.profile-card.ant-card .ant-card-head-title {
  color: MainStyle.$theme-btn !important;
  font-size: 18px;
  display: flex;
  justify-content: center;
}

.aivolvex-profile-outline .ant-color-picker-input {
  color: #000 !important;
}

.profile-card .ant-upload .ant-btn-default {
  background-color: MainStyle.$theme-background-secondary;
  color: MainStyle.$theme-conversation-font;
  border-color: MainStyle.$theme-font-secondary;
}

.ant-color-picker
  .ant-color-picker-inner-content
  .ant-color-picker-input-container
  .ant-color-picker-input
  .ant-color-picker-hex-input.ant-input-affix-wrapper
  .ant-input {
  color: #000 !important;
}

.ant-color-picker-trigger {
  background-color: transparent !important;
  color: MainStyle.$theme-font-primary !important;
}

.ant-color-picker-trigger .ant-color-picker-trigger-text {
  color: MainStyle.$theme-font-primary !important;
}

.card-design {
  display: flex;
  justify-content: center;
}

.profile-card .ant-upload-wrapper .ant-upload-list {
  padding: 5px !important;
}

.profile-card .ant-btn-primary {
  color: MainStyle.$theme-font-primary !important;
  background-color: MainStyle.$theme-btn !important;
  border: none !important;
  margin: 2px;
}

.submit-button-form {
  display: flex;
  justify-content: end;
}

.pro-main-body {
  min-height: 100vh;
  padding: 1rem;
  margin-top: 4rem;
}

.recent-prompt-table-head {
  color: MainStyle.$theme-font-primary !important;
}

.tab-tags.ant-tabs .ant-tabs-tab-btn {
  width: 150px !important;
  font-size: 17px;
  text-align: center !important;
  color: MainStyle.$theme-font-primary;
}

.uploadicon-img {
  color: MainStyle.$theme-font-primary !important;
  font-size: 26px;
  margin-top: 0.4rem;
  margin-right: 0.2rem;
}

.pink-color {
  color: MainStyle.$theme-btn;
  font-size: 20px;
}

.ash-color {
  color: MainStyle.$theme-font-secondary;
}

.profile-tab-body {
  background-color: MainStyle.$theme-background-third;
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.uploaded-image-body {
  // width: 3rem;
  height: 2rem;
  // padding: 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploaded-image {
  width: 100%;
  height: 100%;
}
.profile-theme-select {
  width: 100%;
}
.mobile-account-body {
  padding: 2.5rem 2rem;
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .mobile-account-body {
    padding: 1rem 0.8rem;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .mobile-account-body {
    padding: 1rem 0.8rem;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .mobile-account-body {
    padding: 1rem 0.8rem;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .mobile-account-body {
    padding: 1rem 0.8rem;
  }
}

@media (max-width: 320px) {
  .mobile-account-body {
    padding: 1rem 0.8rem;
  }
}
