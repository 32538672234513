@use "../../../MainStyle";

:root {
  --chat_site_color: MainStyle.$theme-background-secondary;
  --chatblue-gray-color: #24264c;
  --chat_background_color: #080a1e;
  --chat_lite_site_color: #e7e7e7;
  --white-color: MainStyle.$theme-font-primary;
  --purple-color: MainStyle.$theme-btn;
  --gray_backgroud_color: #3a3d63;
}

.icon-size {
  font-size: 20px;
}

.costimize-bootstrap-table {
  background: linear-gradient(
    to top,
    transparent,
    rgb(255, 255, 255),
    rgb(255, 255, 255)
  );
  border-radius: 10px;
  padding: 0.6px;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  border: 0.2px solid transparent;
  border-bottom: none !important;
  height: 59% !important;
}

/* .costimize-bootstrap-table:after {
  content: " ";
  position: absolute;
  border-left: 1px #6c757d solid;
  top: 35%;
  right: 0;
  height: 30%;
  margin-top: auto;
  margin-bottom: auto;
} */

.costimize-bootstrap-table thead th {
  background-color: MainStyle.$theme-font-primary !important;
  color: MainStyle.$theme-font-primary !important;
  border: none !important;
  /* border-radius: 8px; */
  /* border-top:2px solid white !important;; */
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  text-align: center !important;
  z-index: 0 !important;
}
// .ant-select-item
//   .ant-select-item-option
//   .ant-select-item-option-active
//   .ant-select-item-option-selected {
//   color: aqua !important;
// }
.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  // color: aqua !important;
  background-color: MainStyle.$theme-btn !important;
}
.costimize-bootstrap-table
  .ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  border-radius: 0px 4dvb 4px 0px;
  color: MainStyle.$theme-font-primary !important;
  border: 1px solid MainStyle.$theme-font-primary !important;
  border-radius: 0px !important;
}
.no-data {
  font-size: 24px;
  font-weight: 500;
  color: MainStyle.$theme-font-primary !important;
  display: flex;
  justify-content: center;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: MainStyle.$theme-background-primary !important;
}
.no-data-icon {
  font-size: 35px;
  font-weight: 500;

  color: MainStyle.$theme-font-primary !important;
}
.costimize-bootstrap-table
  .ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  /* top-left top-right bottom-right bottom-left */
  border-radius: 4px 0px 0px 4px;
  color: MainStyle.$theme-font-primary !important;
  border: 1px solid MainStyle.$theme-font-primary !important;
  border-radius: 0px !important;
}

.costimize-bootstrap-table .ant-pagination .ant-pagination-item {
  margin: 0px;
  border-radius: 0px !important;
  border: 1px solid MainStyle.$theme-font-primary !important;
}

li.ant-pagination-prev.ant-pagination-disabled {
  margin: 0px;
}

.costimize-bootstrap-table
  .ant-table-wrapper
  .ant-table-pagination.ant-pagination {
  margin: 16px !important;
  margin-right: 16px !important;
}

.costimize-bootstrap-table .ant-pagination .ant-pagination-item-active {
  background: MainStyle.$theme-font-secondary !important;
  color: MainStyle.$theme-font-primary !important;
}

.costimize-bootstrap-table .anticon {
  display: flex !important;
  justify-content: center !important;
}

li.ant-pagination-item {
  // color: MainStyle.$theme-font-primary;
  // color:MainStyle.$theme-font-secondary !important;
  // border: 1px solid MainStyle.$theme-font-secondary !important;
  border: 1px solid MainStyle.$theme-font-primary !important;
}

.costimize-bootstrap-table .ant-table {
  /* background: transparent !important; */
  background: MainStyle.$theme-background-secondary;
  /* background-color: MainStyle.$theme-font-primaryfff00 !important; */
  color: MainStyle.$theme-font-primary;
}
.recent-search-input-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.costimize-bootstrap-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none;
  /* border-bottom: 1px solid #848484 !important; */
  border-bottom: 0.563px solid MainStyle.$theme-font-secondary !important;
}

.costimize-bootstrap-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-empty-description {
  color: MainStyle.$theme-font-primary !important;
}

tr.ant-table-placeholder {
  background: transparent !important;
}

tr.ant-table-placeholder:hover {
  background: transparent !important;
}

// li.ant-pagination-item {
//   // color: MainStyle.$theme-font-primary;
//     color:MainStyle.$theme-font-secondary!important;
// }

.ant-pagination-item-active {
  color: MainStyle.$theme-font-secondary !important;
}

.recent-prompt-table-head {
  margin-top: 0.5rem;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.recent-prompt-table {
  padding: 5px;
  display: flex;
  justify-content: center;
}

.recent-prompt-search {
  display: flex;
  justify-content: left;
}

.recent-search {
  border: 1px solid MainStyle.$theme-font-primary !important;

  background: transparent;
  //  padding: 5px;
  display: flex;
  border-radius: 4px;
  margin: 0.5rem;
}

.recent-search .recent-search-dropdown .ant-select-selector {
  background: transparent !important;
  padding: 5px 10px 5px 5px;
  border: none;
  border-radius: 0px;
  border-right: 1px solid #b9bac4 !important;
  height: 100%;
  color: MainStyle.$theme-font-primary;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  // font-weight: 600;
  width: 6rem;
}

.recent-search
  .recent-search-dropdown
  .ant-select-selector
  .ant-select-selection-item {
  color: MainStyle.$theme-title-font;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  // font-weight: 600;
}

.recent-search .recent-search-dropdown .ant-select-arrow {
  color: MainStyle.$theme-font-primary;
}

.recent-search-input {
  background: transparent;
  padding: 5px;
  height: 75%;
  border: none;
  border-radius: 4px;
  color: MainStyle.$theme-font-secondary !important;
  font-family: Poppins;
  // font-size: 18px;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  height: 0px !important;
}

.his-button {
  background: MainStyle.$theme-background-primary;
  border-radius: 4px;
  color: MainStyle.$theme-font-primary;
  border: none;
}

.his-delete {
  background: #fb4b4b !important;
}

.his-delete:hover {
  background: #fb4b4b !important;
  color: MainStyle.$theme-font-primary !important;
}

.his-delete-text {
  padding-left: 5px;
}

.history_btn {
  width: 40px;
  padding: 0px;
}

.his-button:hover {
  color: MainStyle.$theme-font-primary !important;
  background: MainStyle.$theme-background-primary !important;
}

.costimize-bootstrap-table .ant-select-selector {
  display: none !important;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
  color: MainStyle.$theme-font-primary !important;
}

.recent-prompts-table {
  width: 100%;
  overflow-x: scroll;
}

.recent-prompts-table thead th {
  /* background-color: MainStyle.$theme-background-primary !important;
    border-radius: 13.226px 13.226px 0px 0px; */
  background: rgba(255, 255, 255, 0.06) !important;

  /* background-color: MainStyle.$theme-font-primarye00; */
  color: MainStyle.$theme-font-primary !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  text-align: center !important;
  z-index: 0 !important;
}

.recent-prompts-table .ant-table {
  background: MainStyle.$theme-background-third !important;
  color: MainStyle.$theme-font-primary;
}

.recent-prompts-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none;
  border-bottom: 1px solid #545454 !important;
}

.recent-prompts-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.recent-prompts-table a:hover {
  color: var(--purple-color);
}

.recent-prompts-table
  .ant-pagination
  .ant-pagination-disabled:hover
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.recent-prompts-table .ant-table-tbody {
  background-color: MainStyle.$theme-background-primary;
  color: MainStyle.$theme-font-primary;
}
.recent-prompts-table
  .ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.recent-prompts-table
  .ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.recent-prompts-table .ant-pagination .ant-pagination-item a {
  color: MainStyle.$theme-font-primary !important;
  background: transparent !important;
}

.recent-prompts-table .ant-pagination .ant-pagination-item-active a {
  color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.recent-prompts-table .ant-pagination .ant-pagination-item-active {
  color: MainStyle.$theme-btn !important;
  border-color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.recent-prompts-table .ant-pagination {
  color: MainStyle.$theme-font-primary;
}

.mobile-view-table
  .ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.mobile-view-table
  .ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.mobile-view-table .ant-pagination .ant-pagination-item a {
  color: MainStyle.$theme-font-primary !important;
  background: transparent !important;
}

.mobile-view-table .ant-pagination .ant-pagination-item-active a {
  color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.mobile-view-table .ant-pagination .ant-pagination-item-active {
  color: MainStyle.$theme-btn !important;
  border-color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.mobile-view-table .ant-pagination {
  color: MainStyle.$theme-font-primary;
  margin-bottom: 3rem !important;
  padding-right: 1rem;
}

.ant-pagination-item-ellipsis {
  color: MainStyle.$theme-font-primary !important;
}

/* .history-search {
  display: flex !important;
  justify-content: right !important;
} */

/* .history-search.ant-input-affix-wrapper>input.ant-input {
  background: transparent !important;
} */

.ant-input-affix-wrapper > input.ant-input {
  background: transparent !important;
}

.his-col {
  width: 25px !important;
}
.mobile-background {
  // background-color: MainStyle.$theme-background-third;
}

.recent-mobile-body {
  height: 78vh;
  overflow-y: scroll;
  background-color: MainStyle.$theme-background-secondary;
  padding-bottom: 2rem;
}

.mobile-view-table .ant-table {
  border-radius: 0px;
}

/* 
.mobile-view-table .ant-table .ant-table-thead {
  border-radius: 0px;
  border: none;
  background-color: MainStyle.$theme-background-secondary;
} */
.mobile-view-table .ant-table-thead > tr > th {
  border-radius: 0px !important;
  border-top: 0.5px solid var(--gray_backgroud_color);
  border-left: none;
  border-bottom: none;
  border-right: none;
  background-color: MainStyle.$theme-background-third !important;
  /* background: rgba(41, 43, 85, 0.50); */
  color: MainStyle.$theme-font-primary !important;
}

.ant-table-wrapper .mobile-view-table .ant-table {
  background: rgba(41, 43, 85, 0.5);
  color: MainStyle.$theme-font-primary !important;
  border-radius: 0px !important;
}

/* .recent-mobile-view-table .ant-table-wrapper {
  background-color: transparent !important;
  color:MainStyle.$theme-font-primary !important
  stroke-width: 0.563px;
stroke: rgba(132, 132, 132, 0.50);
} */
.mobile-view-table tbody tr td {
  background-color: MainStyle.$theme-background-primary;
  color: MainStyle.$theme-font-primary;
  /* border-top: rgba(132, 132, 132, 0.50) !important; */
  border-bottom: rgba(132, 132, 132, 0.5) !important;
  border-left: none;
  border-right: none;
}

/* .ant-table-wrapper .mobile-view-table .ant-table .ant-table-tbody {
  background: rgba(41, 43, 85, 0.50);
  color: MainStyle.$theme-font-primary !important;
  border-radius: 0px !important;
}

.mobile-view-table .ant-table-tbody> {
  background: linear-gradient(to right, #f0f5ff, #e1f5fe);
} */
@media only screen and (max-width: 768px) and (min-width: 427px) {
  .recent-search {
    border: 1px solid MainStyle.$theme-font-primary !important;
    background: transparent;
    padding: 2px;
    display: flex;
    border-radius: 10px;
    margin: 0.5rem;
    width: 98vw;
  }
  .recent-prompt-table-head {
    display: none !important;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .recent-search {
    /* border: 1px solid #d736d1 !important; */
    /* border: none; */
    background: transparent;
    border: 1px solid MainStyle.$theme-font-primary !important;
    padding: 2px;
    display: flex;
    border-radius: 10px;
    margin: 0.5rem;
    width: 96vw;
  }
  .recent-prompt-table-head {
    display: none !important;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .recent-search {
    /* border: none; */
    border: 1px solid MainStyle.$theme-font-primary !important;
    background: transparent;
    padding: 2px;
    display: flex;
    border-radius: 10px;
    margin: 0.5rem;
    width: 95vw;
  }
  .recent-prompt-table-head {
    display: none !important;
  }
}

@media (max-width: 320px) {
  .recent-search {
    border: 1px solid MainStyle.$theme-font-primary !important;
    background: transparent;
    padding: 2px;
    display: flex;
    border-radius: 10px;
    margin: 0.5rem;
    width: 92vw;
  }
  .recent-prompt-table-head {
    display: none !important;
  }
}
