@use "../../../MainStyle";
:root {
  --chat_site_color: MainStyle.$theme-background-secondary;
  --chat_background_color: #080a1e;
  --chat_lite_site_color: #e7e7e7;
  --white-color: MainStyle.$theme-font-primary;
  --purple-color: MainStyle.$theme-btn;
  --gray_backgroud_color: #3a3d63;
}

.cursor {
  cursor: pointer !important;
}

.dashboard-board-background {
  /* background: MainStyle.$theme-background-third; */
  /* background-color: MainStyle.$theme-background-secondary; */
  background-color: MainStyle.$theme-background-secondary;
  /* padding: 10px; */
  margin-top: 3.5rem;
}

.dashboard-board-private {
  margin-top: 5rem;
  padding: 6px;
}

/* background: MainStyle.$theme-background-third; */
.chatbot-dashboard-main-body {
  /* width: 100%; */
}

.chatdashboard-record-dection {
  padding: 10px;

  /* width: 100%; */
}

.chatdashboard-record-dection .ant-tabs {
  color: #848484;
}

.chatdashboard-record-dection .ant-tabs .ant-tabs-tab:hover {
  color: #848484 !important;
}

.chart-icon-voice {
  color: #1faeeb;
}

.chart-icon-chat {
  color: #8ac926;
}

.chatdashboard-record-dection .ant-tabs .ant-tabs-tab {
  font-size: 22px !important;
  font-weight: 600;
  width: 15rem;
  justify-content: center;
}

.chatbot-dashboard-card {
  padding: 7px;
  border-radius: 7px;
  padding-left: 1rem !important;
  border: none;
  background: MainStyle.$theme-background-third;
  backdrop-filter: blur(5.627785682678223px);
  /* line-height: 1.8rem !important; */
  height: 100%;
}

.chatbot-dashboard-card-chart {
  height: 100%;
}

.chatbot-dashboard-card-button {
  border-radius: 50%;
  border-color: var(--purple-color);
  /* height: 2rem; */
  background-color: MainStyle.$theme-btn;
  padding: 6px;
  height: 3.5rem;
  width: 3.5rem;
}

.aivolvex-chatbot-dashboard-outline-setup {
  background: linear-gradient(to top, transparent, #848484, #848484) !important;
  border-radius: 7px;
  padding: 0.8px;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.chatbot-dashboard-card-icon {
  color: var(--purple-color);
  padding-bottom: 2px;
}

.chatbot-dashboard-card-text-primary {
  font-size: 17px;
  color: MainStyle.$theme-font-primary;
  font-weight: 400;
}

.chatbot-dashboard-card-text-secondary {
  font-size: 20px;
  color: MainStyle.$theme-font-primary;
  font-weight: 500;
}

.chatbot-dashboard-card-chart-header {
  font-size: 16px;
  font-weight: 500;
  color: MainStyle.$theme-font-primary;
}

.dashboard-board-background-table {
  background: MainStyle.$theme-background-third;
  border-radius: 10px;
  /* padding: 5px; */
}

.ant-tag-purple {
  border-radius: 12.509px;
  border: 0.834px solid MainStyle.$theme-btn;
  /* color: MainStyle.$theme-btn; */
  color: MainStyle.$theme-font-primary;
  background: rgba(215, 54, 209, 0.2);
}

.ant-tag-blue {
  border-radius: 12.509px;
  border: 0.834px solid #1ba9d6;
  /* color: #1BA9D6;
     */
  color: MainStyle.$theme-font-primary;
  background: rgba(54, 138, 215, 0.2);
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  // margin: 0px;
  padding-top: 16px;
  padding-bottom: 5px;
  /* background-color: MainStyle.$theme-background-secondary; */
  background: transparent;
  border-bottom: 4px;
}
.ant-table-wrapper .ant-table-pagination {
  display: inline-flex;
  flex-wrap: nowrap;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin-bottom: 2rem;
  margin-right: 1rem;
  float: right;
}
.anticon {
  vertical-align: 0 !important;
}

.record-history-tab .ant-tabs .ant-tabs-tab {
  font-size: 16px !important;
  font-weight: 500;
  width: 7rem;
  justify-content: center;
}

.record-history-tab .ant-tabs {
  color: #848484;
}

.view-more {
  color: var(--purple-color);
}

.record-history-tab .ant-tabs .ant-tabs-content {
  max-height: 60vh;
  overflow-y: scroll;
}

.volvex-private-podlist {
  /* background-color: #252746; */
  padding: 1rem 0rem 0rem;
  background: MainStyle.$theme-background-third;
}

.chatbot-dashboard-card-item {
  padding: 6px;
}

.summary-para {
  display: flex;
  justify-content: center;
}

.history-record-table .ant-pagination-item-link .anticon {
  color: MainStyle.$theme-font-primary;
}

.report-table-modal .ant-modal-content {
  /* background-color: MainStyle.$theme-background-primary !important; */
}

.report-table-modal .ant-modal .ant-modal-body {
  /* background: MainStyle.$theme-background-primary !important; */
}

/* .history-record-table {
    border: none;
}

.history-record-table .ant-table-wrapper .ant-table {
    background: #24264c !important;
    color: MainStyle.$theme-font-primary;

}

.history-record-table .ant-table-thead>tr>th {
    background: MainStyle.$theme-background-primary !important;
    color: MainStyle.$theme-font-primary !important;
    transition: background 0.2s ease;
    border-bottom: transparent;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.history-record-table .ant-table-wrapper .ant-table-thead>tr>td {
    background: MainStyle.$theme-background-primary !important;
    color: MainStyle.$theme-font-primary !important;
    border: 1px solid MainStyle.$theme-background-primary;
   
}

.history-record-table .ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background-color: transparent;
}

.history-record-table .ant-table-wrapper .ant-table-pagination.ant-pagination {
    background: #24264c !important;
} */

/* .ant-table-wrapper .ant-table-thead >tr>td { */
.history-record-table .ant-table-wrapper .ant-table-pagination.ant-pagination {
  background: #24264c !important;
}

.dashboard-board-private .ant-collapse {
  border: none !important;
  color: MainStyle.$theme-font-primary !important;
}

.dashboard-board-private .ant-collapse > .ant-collapse-item {
  border: none !important;
  color: MainStyle.$theme-font-primary !important;
}

.dashboard-board-private
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  color: MainStyle.$theme-font-primary !important;
  font-size: 20px;
  font-weight: 500px;
  font-family: "Poppins";
}

.dashboard-board-private .ant-collapse .ant-collapse-content {
  color: MainStyle.$theme-font-primary !important;
  background: transparent;
}

.dashboard-board-private .ant-collapse .ant-collapse-content {
  border-top: none;
}

.summary-text {
  color: MainStyle.$theme-font-primary;
  font-family: "Poppins";
  padding: 1rem 5rem;
  /* text-align: center; */
}

.chatdashboard-record-dection
  .ant-tabs
  .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: MainStyle.$theme-font-secondary;
}

.chatdashboard-record-dection
  .ant-tabs
  .ant-tabs-tab-remove:focus:not(:focus-visible) {
  color: MainStyle.$theme-font-secondary;
}

.chatdashboard-record-dection .ant-tabs .ant-tabs-tab-btn:active {
  color: MainStyle.$theme-font-secondary;
}

.chatdashboard-record-dection .ant-tabs .ant-tabs-tab-remove:active {
  color: MainStyle.$theme-font-secondary;
}

.record-history-tab .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: MainStyle.$theme-font-secondary;
}

.record-history-tab .ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible) {
  color: MainStyle.$theme-font-secondary;
}

.record-history-tab .ant-tabs .ant-tabs-tab-btn:active {
  color: MainStyle.$theme-font-secondary;
}

.record-history-tab .ant-tabs .ant-tabs-tab-remove:active {
  color: MainStyle.$theme-font-secondary;
}

.record-history-tab .ant-tabs .ant-tabs-tab:hover {
  color: MainStyle.$theme-font-secondary !important;
}

.history-tab-row {
  padding: 5px;
  border-bottom: 1px solid#848484;
}

.puple-font {
  color: MainStyle.$theme-btn;
}

.white-font {
  color: MainStyle.$theme-font-primary;
}

.text-end {
  display: flex;
  justify-content: end;
}

.record-dashboard-table {
  width: 100%;
  overflow-x: scroll;
}

.ant-message .ant-message-notice .ant-message-notice-content {
  background-color: MainStyle.$theme-background-third;
  color: MainStyle.$theme-font-primary;
}

.dashboard-board-background-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.dashboard-board-private
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  height: 31px;
}

.record-table-content {
  margin-bottom: 3rem;
}
.record-dashboard-table table {
  border: none !important;
}
.record-dashboard-table thead th {
  /* background-color: MainStyle.$theme-background-primary !important;
    border-radius: 13.226px 13.226px 0px 0px; */
  background-color: MainStyle.$theme-background-primary !important;

  /* background-color: MainStyle.$theme-font-primarye00; */
  color: MainStyle.$theme-font-primary !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  /* text-align: center !important; */
  z-index: 0 !important;
}

.record-dashboard-table .ant-table {
  background: transparent !important;
  color: MainStyle.$theme-font-primary;
}

.record-dashboard-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none !important;
  border-bottom: 1px solid #545454 !important;
}

.record-dashboard-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.record-dashboard-table a:hover {
  color: var(--purple-color);
}

.question-font {
  font-weight: 700;
}

.record-dashboard-table
  .ant-pagination
  .ant-pagination-disabled:hover
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.record-dashboard-table .ant-pagination {
  color: MainStyle.$theme-font-primary;
}

.record-dashboard-table
  .ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.record-dashboard-table
  .ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: MainStyle.$theme-font-primary;
}

.record-dashboard-table .ant-pagination .ant-pagination-item a {
  color: MainStyle.$theme-font-primary !important;
  background: transparent !important;
}

.record-dashboard-table .ant-pagination .ant-pagination-item-active a {
  color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.record-dashboard-table .ant-pagination .ant-pagination-item-active {
  color: MainStyle.$theme-btn !important;
  border-color: MainStyle.$theme-btn !important;
  background: transparent !important;
}

.record-dashboard-popup
  .ant-popconfirm
  .ant-popconfirm-message
  .ant-popconfirm-description {
  color: MainStyle.$theme-font-primary !important;
}

.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-description {
  color: MainStyle.$theme-font-primary;
}

/* .ant-modal .ant-modal-close {
    background-color: var(--purple-color) !important;  
} */

.report-table-modal .ant-modal-content .ant-modal-close {
  /* background-color: var(--purple-color) !important; */
  background-color: #7f2588;
}

.smiley-text {
  padding-top: 0.12rem;
  padding-left: 0.2rem;
}

.history-token {
  color: MainStyle.$theme-font-primary;
  margin-top: 1rem;
}

.history-token-logo {
  width: 25px;
}

.ant-tag-green {
  color: MainStyle.$theme-font-primary;
  border-radius: 12.509px;
  border-color: #2fb14c;
  background: rgba(47, 177, 76, 0.34);
}

.ant-tag-red {
  color: MainStyle.$theme-font-primary;
  border-radius: 12.509px;
  border-color: #ff3f32;
  background: rgba(255, 121, 121, 0.2);
}

.fh-100 {
  height: 100vh;
}

.content-formate {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: auto;
  color: MainStyle.$theme-font-primary;
}

/* border-radius: 12.509px;
border: 0.834px solid #FF3F32;
background: rgba(255, 121, 121, 0.20); */
.dashboard-board-private {
  margin-top: 5rem;
  padding: 6px;
}

.dashboard-mobile-body {
  height: 78vh;
  /* padding: 5px; */
  /* overflow-y: scroll; */
}

.public-tab.ant-select-selector {
  background-color: var(--purple-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}

.public-tab .ant-select .ant-select-selector {
  background-color: var(--purple-color) !important;
  color: var(--white-color) !important;
  border: none !important;
}

.public-tab .ant-select .ant-select-arrow {
  color: var(--white-color) !important;
  font-weight: 600;
}

.public-tab .ant-select .ant-select-selector .ant-select-selection-item {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: var(--white-color) !important;
}

.ant-select .public-tab {
  background-color: var(--purple-color) !important;
  color: var(--white-color) !important;
}

.chat-mobilebottom-public {
  padding: 0.5rem;
}

.chat-mobile-public-conversation {
  overflow-y: scroll;
  min-height: 80vh;
  margin-bottom: 4rem;
}

.public-agent-desktop {
  display: block;
}

.public-agent-mobile {
  display: none;
}

.ant-pagination .ant-pagination-options {
  display: block;
}
.qa-check-tick {
  color: #00a300;
}
.qa-check-cross {
  color: #ff0000;
}
@media only screen and (max-width: 768px) and (min-width: 427px) {
  .dashboard-board-private {
    margin-top: 0rem;
    padding: 6px;
  }

  .chatbot-dashboard-mobile {
    padding: 1rem !important;
  }

  .public-agent-mobile {
    display: block;
  }

  .public-agent-desktop {
    display: none;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    float: right;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .dashboard-board-private {
    margin-top: 0rem;
    padding: 6px;
  }

  /* .ant-tabs-tab {
    width: 140px;
  } */

  .chatbot-dashboard-mobile {
    padding: 1rem !important;
  }

  .public-agent-mobile {
    display: block;
  }

  .public-agent-desktop {
    display: none;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    float: left;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .dashboard-board-private {
    margin-top: 0rem;
    padding: 6px;
  }

  /* 
  .ant-tabs-tab {
    width: 122px;
  } */

  .chatbot-dashboard-mobile {
    padding: 1rem !important;
  }

  .public-agent-mobile {
    display: block;
  }

  .public-agent-desktop {
    display: none;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    float: left;
  }
}

@media (max-width: 320px) {
  .dashboard-board-private {
    margin-top: 0rem;
    padding: 6px;
  }

  .chatdashboard-record-dection .ant-tabs .ant-tabs-tab {
    width: 10rem;
  }

  /* .ant-tabs-tab {
    width: 104px;
  } */

  .chatbot-dashboard-mobile {
    padding: 1rem !important;
  }

  .public-agent-mobile {
    display: block;
  }

  .public-agent-desktop {
    display: none;
  }
  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    float: left;
  }
}
