.scroll-summary {
  overflow-y: auto;
}
.pca-ai-message-replay {
  background: var(--con_title_and_input_color);
  border-radius: 0.5rem;
  border: 1.5px solid #ababab;
  padding: 0.5rem;
  margin-right: 0.5rem;
  max-width: 500px;
  color: white;
  margin-top: 2px;
}


.call-height {
  height: 463px;
}
.pca-chatbot-ai {
  background-color: #fff;
  color: var(--theme-dashboard-background);
  border: 1.5px solid var(--theme-dashboard-background);
}

.customer-detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.customer-detail b {
  width: 150px; 
}

.customer-detail .colon {
  margin-left: 10px; /* Adjust the space before the colon as needed */
  margin-right: 10px; /* Adjust the space after the colon as needed */
}

.pca-ai-message {
  background: var(--theme_secondary_color);
  border: 1.5px solid #ababab;
  border-radius: 0.5rem;
  /* max-width: 428px; */
  /* border: 1.5px solid var(--theme-dashboard-background); */
  padding: 0.5rem;
  margin-left: 0.5rem;
  color: white;
  margin-top: 3px;
}



.pca-ai-message1 {
  background: var(--theme_secondary_color);
  border: 1.5px solid #ababab;
  border-radius: 0.5rem;
  /* max-width: 428px; */
  /* border: 1.5px solid var(--theme-dashboard-background); */
  padding: 0.5rem;
  margin-left: 0.5rem;
  color: white;
  width: 282px;
  margin-top: 3px;
}

.pca-chatbot-ai-replay {
  background-color: #fff;
  color: var(--theme-dashboard-background);
  border: 1.5px solid var(--theme-dashboard-background);
}

.scrollable-content {
  height: 250px;
  overflow-y: scroll;
}
.scrollable-transcript {
  height: 330px;
  overflow-y: scroll;
}

.scrollable-summary {
  height: 75px;
  overflow-y: scroll;
}
.custom-steps .ant-steps-item-title,
.custom-steps .ant-steps-item-description,
.custom-steps .ant-steps-item-icon,
.custom-steps .ant-steps-item-tail {
  color: white !important;
}
.margin-top {
  margin-top: 2rem;
}

.text-in {
  width: 68%;
  /* margin-left: 27px; */
}
.text-in-input{
  width:68%
}
.format-cs {
  color: white !important;
  background: linear-gradient(
    96deg,
    #eb77e7 -52.87%,
    #d736d1 25.34%,
    #9340e8 113.98%
  );
}
.sub-btn{
   background:  linear-gradient(96deg, #eb77e7 -52.87%, #d736d1 25.34%, #9340e8 113.98%);
   /* display: flex;
   justify-content: center; */
}

.margin {
  margin-left: 4px;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}

.post-btn {
  width: 28%;
  color: white;
}

.tooltip-pointer {
  cursor: pointer;
}

.custom-steps-tail
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #eb77e7;
}
.custom-steps-tail
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #eb77e7;
}
.custom-steps-tail
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #eb77e7;
}

.custom-dot .ant-steps-icon-dot {
  background: #9340e8 !important;
}

.font-size-title {
  font-size: 19px;
}

.topic-bold {
  font-weight: 600;
  color: white;
}

.pcaprofile {
  overflow-y: auto;
}

.profileheight {
  max-height: 96%;
  overflow-y: auto;
}

.overall-height{
  height: 230px;
}

.customerprofile {
  height: 50px;
}
.lastcall-history {
  color: #d736d1;
}

.selected{
  color: #d736d1 !important;
}

.dash-height{
  height:447px;
}
.border-box-col {
  border: 1px solid #ddd; 
  padding: 16px; 
  border-radius: 6px; 
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.border-box-chat {
  border: 1px solid #ddd; 
  padding: 16px; 
  border-radius: 6px; 
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.scroll-ques{
  overflow-y: auto;
  height:463px;
}
.success-tag {
  padding: 2px 10px;
  color: #048800 !important;
  font-size: 13px;
  font-weight: 400;
  border-radius: 49px;
  border: 1px solid #048800;
  background: #e1ffe0;
  margin-bottom: 5px;
  display: inline-block;
}
.success-height{
  height: 79px;
  overflow-y: auto;
}

.Issue-height{
  height: 79px;
  overflow-y: auto;
}

.tab-item {
  margin-right: 10px; 
}
.custom-tabs .ant-tabs-tab:not(.ant-tabs-tab-active) {
  color: grey;
}
.tab-item.inactive {
  color: grey;
}


.issue-tag {
  padding: 2px 10px;
  color: #f94144 !important;
  font-size: 13px;
  border-radius: 49px;
  font-weight: 400;
  border: 1px solid #f94144;
  background: #ffe0e1;
  margin-bottom: 5px;
  display: inline-block;
}

.neutral-tag {
  padding: 2px 10px;
  color:rgb(255, 196, 0) !important;
  background: rgb(246, 238, 212);
  font-size: 13px;
  border-radius: 49px;
  font-weight: 400;
  border: 1px solid rgb(255, 196, 0);
  margin-bottom: 5px;
  display: inline-block;
}


.ticket-color{
  color:rgb(255, 196, 0) !important;
}

.action-item {
  height: 238px;
}

.dashboard-scroll {
  overflow-y: auto;
}
/* .pca-margin{
  margin-left: 2rem;
} */

.label-grey {
  margin-left: 10px;
}

.tabs .ant-tabs-tab {
  color: grey;
}

.pca-chatbot-button {
  margin-top: 6rem;
}

.pca-qa-body {
  overflow-y: auto;
  height: 132px;
}

.transcript-height {
  overflow-y: auto;
  height: 176px;
}

.horizontal-steps-container {
  overflow-x: auto;
}

/* .pca-df{
  padding-left: 0px !important;
} */

.chat-height {
  height: 150px;
}

.ticket-raise {
  color: #d736d1;
}

.margin-lef {
  margin-left: 3rem;
  margin-top: 6px;
}

.height-stepper {
  height: 101px;
}

.ticket-raise-btn {
  cursor: pointer;
  color: linear-gradient(
    96deg,
    #eb77e7 -52.87%,
    #d736d1 25.34%,
    #9340e8 113.98%
  );
}
.phn-icon-call {
  border: 1px solid #d736d1;
  background: linear-gradient(
    96deg,
    #eb77e7 -52.87%,
    #d736d1 25.34%,
    #9340e8 113.98%
  );
}

.knowl {
  margin-left: 3rem;
  margin-top: 3px;
}

.phn-icon-miss {
  border: 1px solid #f94144;
  background: #ffe0e1;
}

.height-posttrans {
  overflow-y: auto;
  height: 175px;
}

.postai-height {
  height: 445px;
}

.pcacall-height {
  height: 343px;
  overflow-y: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18rem;
}
.pca-ai-assist-title-body {
  display: flex;
  justify-content: space-between;
}
.pca-ai-assist-callhistory {
  color: #fff;
}
.pca-chatbot-footer {
  height: 180px;
}

.pca-chatbot-footer-message {
  width: 315px;
}

.pca-csa-body {
  height: 39px;
  overflow: overlay;
}

.opp-pca {
  height: 30px;
  overflow-y: auto;
}

.green-warning {
  color: #389e0d;
}

.red-warning {
  color: #f94144;
}
.postive-tag-scroll {
  height: 84px;
  overflow-y: auto;
}
.opp-height {
  height: 188px;
}
.div-chat{
  background-color: #4b4a4a;
  height: 521px;
}

.font-size{
  font-size: 10px;
}

.step-margin{
  margin-top: 0rem;
  margin-left: -2rem;
}

.no-data-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  text-align: center;
}

.no-data-found {
  font-size: 2rem;
  color: white;
}

/* Tooltip.css */

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  visibility: visible;
  color: #fff;
  width: 387px;
  /* background: linear-gradient(96deg, #eb77e7 -52.87%, #d736d1 25.34%, #9340e8 113.98%); */
  text-align: center;
  /* border-radius: 43px; */
  /* padding: 17px 3px; */
  position: absolute;
  z-index: 1;
  /* bottom: 100%; */
  bottom: -105px;
  /* background-color
  /* background-color: black; */
  left: 558%;
  transform: translateX(-50%);
  opacity: 0;
  height: 349px;
}


.tooltip-title {
  padding: 2px;
  background-color: white;
  /* background: linear-gradient(
    96deg,
    #eb77e7 -52.87%,
    #d736d1 25.34%,
    #9340e8 113.98%
  ); */
  border-radius: 5px;
  visibility: visible;
  color: black;
  width: 87px;
  height: 26px;
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 174%;
  transform: translateX(-50%);
  opacity: 0;
  /* height: 349px; */
}
.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.tooltip-container:hover .tooltip-title {
  visibility: visible;
  opacity: 1;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon .ant-steps-icon-dot {
  background: violet;
}

/* .ant-modal-root .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(2px);
} */



/* .custom-model .ant-modal-root .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
} */


.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background: #9340e8 

}


.tooltip-container {
  position: relative;
  display: inline-block;
}
/* .tooltip-container1 {

  position: relative;n
  display: inline-block;
} */
/* .tooltip-stepper {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 301px;
  height: 18rem;
  margin-bottom: 10px;
  z-index: 1000;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
} */



.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; 
}

/* .my-custom-modal-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
  backdrop-filter: blur(3px); 
} */
/* .my-custom-modal-mask .ant-modal-root .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(2px);
} */
.my-custom-modal-mask .ant-modal-root .ant-modal-wrap {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  overflow: auto;
  outline: 0;
  backdrop-filter: blur(1px);
}


/* .pca-width{

    width: 489px;

} */


